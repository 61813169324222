import { Text } from "@mantine/core"
import { profile } from "console"
import { useCallback, useEffect, useRef, useState } from "react"
import ReactJoyride from "react-joyride"
import { useNavigate } from "react-router-dom"
import { PlanInfoCard } from "src/components/Cards/PlanInforCard"
import { ConfirmFeedbackModal } from "src/components/Modals/ConfirmationModal"
import { CustomButton } from "src/components/common/Buttons/CustomButton"
import { IconName } from "src/components/common/Icons/Icons/iconRegistry"
import { useAuth } from "src/hooks/useAuth"
import { usePayments } from "src/hooks/usePayment"
import useResponsive from "src/hooks/useResponsive"
import useViewportHeight from "src/hooks/useViewPortHeight"
import { parentRoutes } from "src/navigators/routes/ParentRoutes"
import { authRoutes } from "src/navigators/routes/auth"
import { clearChatState, resetUser } from "src/redux/redux-store"
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks"
import { clearPaymentState } from "src/redux/redux-store/payment/paymentReducer"
import { ResponseKind } from "src/services/services/MainService"
import { authService } from "src/services/services/auth/auth.api"
import { formatDate } from "src/utils/helpers"
import { clearToken } from "src/utils/storage"

interface BaseSidebar {
    screenName: 'Home' | 'Learner Portal' | 'Learner View' | 'Profile'
    children: React.ReactNode
}

export const BaseSidebar = (props: BaseSidebar) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {logout} = useAuth()
    const {isMobile} = useResponsive()
    const [visible, setVisible] = useState(false)
    const { getCurrentPlan} = usePayments()
    const initializeRef = useRef(false)
    const trialExpired = useAppSelector(state => state.user.trial_expired)
    const userName = useAppSelector(state => state.user.name)
    const userPlan = useAppSelector(state => state.payment.userPlan)
    const trialDays = useAppSelector(state => state.user.trial_days)
  
    const {screenName, children} = props

    // const buttons: {name: string, icon: IconName, navigate: string, className?: string} [] = [
    //     { name: 'Home', icon: 'home', navigate: parentRoutes.DASHBOARD, className: 'home-step'},
    //     // { name: 'Learner Portal', icon: 'list', navigate: parentRoutes.LEARNER_PORTAL},
    //     { name: 'Learner View', icon: 'list', navigate:  parentRoutes.CHILD_VIEW, className: 'learner-chat-history-step'},
    // ];

    const confirmSignOut= () => {
      setVisible(true)
    }

    useEffect(() => {

        const initializePlan = async () => {
            if (initializeRef.current) {
                console.log("--> Skipping due to hasInitialized")
                return
            }
            initializeRef.current = true
            console.log("--> Calling getCurrentPlan")
            await getCurrentPlan()
        }

        initializePlan()
        
        return () => {
            
        }
    }, [])

    const logOut = async () => {
        const response = await authService.signOut();
    
        if (response.kind == ResponseKind.OK) {
          navigate(authRoutes.LOGIN, { replace: true });
          clearToken();
          dispatch(resetUser());
          dispatch(clearChatState());
          dispatch(clearPaymentState());
          logout();
        }
    };

    const goToProfile = async () => {
         navigate(parentRoutes.PROFILE)
    };


    return(
        <div className="flex h-dvh flex-col text-gray-800 bg-white w-full p-4 pt-0">
          <ConfirmFeedbackModal
            title="Sign Out"
            feedback="Are you sure you want to sign out?"
            confirmLabel="Sign Out"
            visible={visible}
            onClose={() => setVisible(false)}
            onConfirm={logOut}
          />
         <div className="flex-shrink-0">
        <div className="flex items-center space-x-3 mt-2 mb-5">
          <div>
            <Text className="font-bold text-xl">{userName}</Text>
            <Text className="text-sm text-gray-600">Parent Portal</Text>
          </div>
        </div>
        </div>

        <div className="space-y-4 mb-6 mt-4">
          <CustomButton            
            leftIcon="home"
            alignment="left"
            className="home-step"
            textClass="font-normal"
            size="md"
            onClick={() => navigate(parentRoutes.DASHBOARD)}
            variant={screenName === "Home" ? "secondary" : "ghost"}
          >
            Ask MYAi
          </CustomButton>
          <CustomButton            
            leftIcon="list"
            alignment="left"
            className="learner-chat-history-step"
            textClass="font-normal"
            size="md"
            onClick={() => navigate(parentRoutes.CHILD_VIEW)}
            variant={screenName === "Learner View" ? "secondary" : "ghost"}
          >
            Learner View
          </CustomButton>
        </div>

        {children}

        
        <div className="flex-shrink-0 mt-auto">
        <CustomButton
          onClick={goToProfile}
          variant={screenName == "Profile" ? "secondary" : "ghost"}
          size="default"
          leftIcon="settings"
          alignment='left'
          className= "settings-step text-sm"
        >
          Settings
        </CustomButton>
  
        <CustomButton
          onClick={confirmSignOut}
          variant="ghost"
          size="default"
          leftIcon="logout"
          alignment='left'
          className= "text-sm text-red-500"
        >
          <Text className="text-red-500 font-semibold">Sign Out</Text>
        </CustomButton>

        <PlanInfoCard
        onUpgrade={() => navigate(parentRoutes.PAYMENT)}
        paid={userPlan?.has_active_subscription}
        plan={userPlan ? userPlan : null}
        trial_days={trialDays}
        trial_expired={trialExpired}
        />
        </div>

      </div>
    )
}