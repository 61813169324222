import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import {
  BackgroundImage,
  Image,
  LoadingOverlay,
  Text,
} from "@mantine/core";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { UserRole } from "src/enums/User";
import { Navigate, useNavigate } from "react-router-dom";
import { getRole } from "src/utils/storage";
import {
  handleUpdateAppSettings,
  handleUserUpdate,
} from "src/redux/redux-store";
import { ChatStyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { Notifications } from "@mantine/notifications";
import useResponsive from "src/hooks/useResponsive";
import DynamicLayout from "src/components/Layout/DynamicLayout";
import ParentChildViewSidebar from "src/components/Sidebars/Parent/ParentSidebarChildView";
import useChildrenData from "src/hooks/useChildrenData";
import ReadOnlyChat from "src/components/ReadOnlyChat";
import { FlaggedData } from "src/interfaces/Chat";
import Icon from "src/components/common/Icons/Icons/Icon";

const ChildView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(state => state.user)
  const { backgroundColor, backgroundColorExterior, isMobile, isDesktop } = useResponsive()
  const { childMessages, selectedChild, flagMessage, getChildMessages, selectedSession } = useChildrenData()

  useEffect(() => {
    if (selectedSession) {
      getChildMessages(selectedChild, selectedSession)
    }
  }, [selectedSession])

  const header = (selectedChild ? <div className="flex flex-row items-center gap-2">
    <Icon name="chat" className="size-6 md:size-8" />
    <Text className="text-lg font-semibold">{selectedChild}</Text>
  </div> : <Text className="text-lg font-semibold">Child View</Text>)

  return (
    <DynamicLayout
      heading={header}
      SidebarComponent={ParentChildViewSidebar}
    >
      <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
        <ChatStyledContainerInset theme={{ backgroundColor: backgroundColor }}>
          <BackgroundImage className="" src="/images/doodles.png">
            <Notifications
              position={isMobile ? "top-center" : "bottom-right"}
              limit={isMobile ? 1 : 5}
            />
            {/* <ErrorMessage error={error ? error : ""} handleClose={dismissError} />  */}
            <LoadingOverlay visible={false} />
            <div className="flex h-full items-center justify-center">
            {selectedChild ? (
              childMessages.length > 0 ? (
                <div 
                // style={{ height: heightStyle }}
                 className="flex h-[calc(100dvh-40px)] lg:h-[calc(100dvh-70px)] flex-col w-full lg:w-[700px] xl:w-[800px] mx-auto overflow-hidden relative">
                  <ReadOnlyChat 
                    childUsername={selectedChild} 
                    onFlagMessage={flagMessage} 
                    messages={childMessages} 
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-4 w-full" 
                // style={{ height: heightStyle }}
                >
                      <Image src={'/landing/logo.png'} mah={120}  maw={120} className="self-center"/>
                  <Text className="text-base text-gray-400 font-semibold">No chat session selected</Text>
                </div>
              )
            ) : (
              <div className="flex flex-col items-center justify-center gap-4 w-full" 
              // style={{ height: heightStyle }}
              >
                    <Image src={'/landing/logo.png'} mah={120}  maw={120} className="self-center"/>
                <Text className="text-base text-gray-400 font-semibold">Please select a child from the sidebar</Text>
              </div>
            )}
             </div>
          </BackgroundImage>
        </ChatStyledContainerInset>
      </StyledContainer>
    </DynamicLayout>
  );
};

export default ChildView;
