import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { COLORS } from "src/utils/styles";
import { Notifications } from "@mantine/notifications";
import useResponsive from "src/hooks/useResponsive";
import DynamicLayout from "src/components/Layout/DynamicLayout";
import RegisterChildForm, {RegisterFormData} from "src/components/Forms/Child/RegisterChild";
import ParentRegisterChildSidebar from "src/components/Sidebars/Parent/ParentSidebarRegisterChild";
import useChildrenData from "src/hooks/useChildrenData";
import { Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { parentRoutes } from "src/navigators/routes/ParentRoutes";
import useViewportHeight from "src/hooks/useViewPortHeight";

const RegisterChildScreen = () => {
  const navigate = useNavigate()
  const { backgroundColor, backgroundColorExterior, isMobile, isDesktop } = useResponsive()
  const {children, createChild, deleteChild, loading} = useChildrenData()
  // useViewportHeight()

  const header = (<div className="flex flex-col">
      <Text size={"lg"} fw={"bold"}>Register Learner</Text>
      <Text className="hidden xl:flex" size={"md"}>Fill in the following details to register learner first.</Text>
    </div>)

let heightStyle;
if (isMobile) {
  // Is tablet or mobile (Max width 1024px)
  heightStyle = 'calc((var(--vh, 1vh) * 100) - 40px)';
} else {
  // Desktop (Min width 1024px)
  heightStyle = 'calc((var(--vh, 1vh) * 100) - 70px)';
}

  return (
    <DynamicLayout
      heading = {header}
      SidebarComponent={ParentRegisterChildSidebar}
    >
    <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
      <StyledContainerInset theme={{ backgroundColor: backgroundColor }}>
        <Notifications
          position={isMobile ? "top-center" : "bottom-right"}
          limit={isMobile ? 1 : 5}
        />
        <div style={{height: heightStyle}} className= 'flex w-full mt-5 xl:mt-0 md:items-center justify-center'>

        <RegisterChildForm loading={loading} onSubmit={async (values: RegisterFormData, cb = () => {}) => {
          let temp = {
            name: values.firstName + " " + values.lastName,
            level: values.grade,
            username: values.username,
            password: values.password,
            school: values.school,
            date_of_birth: `${values.birthYear}-01-01`,
        }

          const res = await createChild(temp, values.image)  
    
          if(res == true){
            cb()
            navigate(-1)
          }
        }}/>
        </div>

      </StyledContainerInset>
    </StyledContainer>
    </DynamicLayout>
  );
};

export default RegisterChildScreen;
