import { Modal, Button, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { CustomButton } from 'src/components/common/Buttons/CustomButton';
import Icon from 'src/components/common/Icons/Icons/Icon';
import { COLORS, FONT_SIZES } from 'src/utils/styles';


interface ConfirmationModal{
title?: string
feedback: string
confirmLabel?: string
onClose?: () => void
onConfirm?: () => void
}

export const confirmDeletionModal = (props: ConfirmationModal) => {
    const {title, feedback, onClose, onConfirm, confirmLabel} = props

  modals.openConfirmModal({
    title: title || 'Please confirm your action',
    centered: true,
    children: (
      <Text size="sm">
          {feedback}
      </Text>
    ),
    labels: { confirm: confirmLabel || "Confirm Delete", cancel: "Cancel" },
    confirmProps: {
        color: 'red',
        styles: { 
          root: {
            backgroundColor: 'red', // Override for 'Delete' button
            color: 'white',
            borderWidth: 0
          }
        }
      },
    onCancel: onClose,
    onConfirm: onConfirm,
  });

}

interface ConfirmFeedbackModal {
  onClose: () => void
  title: string
  feedback: string
  onConfirm: () => void
  visible: boolean
  confirmLabel: string
}
export const ConfirmFeedbackModal = (props: ConfirmFeedbackModal) => {
  const { onClose, onConfirm, visible, title, feedback, confirmLabel} = props

  return(
    <Modal
      opened={visible}
      radius='lg'
      onClose={onClose}
      title={<div className='flex flex-col items-center gap-1'>
      <Text className='font-bold'>{title}</Text>
        </div>}
      styles={{
        title: {
          fontSize: 24,
          fontWeight: 600,
        },
        header: {
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         padding: 0
        },
        body: {
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',

        },
        content:{
          padding: 15,
          paddingBottom: 0
        }
      }}
      withCloseButton = {false}
      centered
    >
      <Text className='my-2 mb-4' size="sm">{feedback}</Text>

      <div className='flex justify-between items-center' >
        <CustomButton variant='outline' size='sm' width='w-1/3' onClick={onClose}>Cancel</CustomButton>
        <CustomButton variant='destructive' size='sm' width='w-1/3' onClick={() => {onConfirm(); onClose()}}>{confirmLabel}</CustomButton>
      </div>
    </Modal>
  )

}