import React, { useState, useCallback } from 'react';
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { Notifications } from "@mantine/notifications";
import { Text, Modal } from "@mantine/core";
import useResponsive from "src/hooks/useResponsive";
import DynamicLayout from "src/components/Layout/DynamicLayout";
import useChildrenData from "src/hooks/useChildrenData";
import ChildList from "src/components/common/Lists/ChildList";
import { useNavigate } from "react-router-dom";
import { parentRoutes } from "src/navigators/routes/ParentRoutes";
import ParentRegisterChildSidebar from "src/components/Sidebars/Parent/ParentSidebarRegisterChild";
import { EditChildForm } from 'src/components/Forms/Child/EditChildForm';
import { ChildUpdationData, ChildrenData } from 'src/interfaces/Children';

const LearnerPortalScreen: React.FC = () => {
  const navigate = useNavigate();
  const { backgroundColor, backgroundColorExterior, isMobile, isDesktop } = useResponsive();
  const { children, deleteChild, updateChild } = useChildrenData();
  const [selectedChild, setSelectedChild] = useState<ChildrenData | null>(null);

  const onAddChild = useCallback(() => {
    window.location.href = parentRoutes.REGISTER_CHILD;
  }, []);

  const onPressChild = useCallback((child: ChildrenData) => {
    setSelectedChild(child);
  }, []);

  const onCloseModal = useCallback(() => {
    setSelectedChild(null);
  }, []);

  const onUpdateChild = useCallback((updatedChildData: Partial<ChildUpdationData>) => {
    if (selectedChild) {
      updateChild(selectedChild.username, updatedChildData);
      onCloseModal();
    }
  }, [selectedChild]);

  const header = (
    <div className="flex flex-col">
      <Text size="lg" fw="bold">Learner Portal</Text>
    </div>
  );

  return (
    <DynamicLayout
      heading={header}
      SidebarComponent={ParentRegisterChildSidebar}
    >
      <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
        <StyledContainerInset theme={{ backgroundColor: backgroundColor }}>
          <Notifications
            position={isMobile ? "top-center" : "bottom-right"}
            limit={isMobile ? 1 : 5}
          />
          {/* <ChildList 
            onPress={onPressChild} 
            onAddChild={onAddChild} 
            children={children} 
            onDeleteChild={deleteChild} 
          /> */}
        <Modal
        opened={!!selectedChild}
        onClose={onCloseModal}
        title={
          <Text size="xl" weight={700}>
            Update Child Information
          </Text>
        }
        fullScreen={isMobile}
        size="lg"
        radius="lg"
        styles={{
          content: {
            padding: '10px',
          },
        }}
      >
            {selectedChild && (
              <EditChildForm
                user={selectedChild}
                onUpdate={(updatedUser) => {
                  onUpdateChild(updatedUser);
                }}
              />
            )}
          </Modal>
        </StyledContainerInset>
      </StyledContainer>
    </DynamicLayout>
  );
};

export default LearnerPortalScreen;
