import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChildrenData, ChildCreationData, ChildUpdationData } from "src/interfaces/Children";
import { ChatMessage, ChatSession, FlaggedData } from "src/interfaces/Chat";

interface ChildrenState {
  children: ChildrenData[];
  initialized: boolean;
  childMessages: ChatMessage[];
  childSessions: ChatSession[];
  selectedSession: string;
  chatLoading: boolean;
  selectedChild: string;
}

const initialState: ChildrenState = {
  initialized: false,
  children: [],
  childMessages: [],
  childSessions: [],
  selectedSession: '',
  chatLoading: false,
  selectedChild: '',
};

const childrenSlice = createSlice({
  name: "children",
  initialState,
  reducers: {
    setChildren: (state, action: PayloadAction<ChildrenData[]>) => {
      state.children = action.payload;
    },
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    addChild: (state, action: PayloadAction<ChildrenData>) => {
      state.children.push(action.payload);
    },
    updateChild: (state, action: PayloadAction<{ id: string; data: Partial<ChildUpdationData> }>) => {
      const index = state.children.findIndex(child => child.id.toString() === action.payload.id);
      if (index !== -1) {
        state.children[index] = { ...state.children[index], ...action.payload.data };
      }
    },
    removeChild: (state, action: PayloadAction<string>) => {
      state.children = state.children.filter(child => child.username.toString() !== action.payload);
    },
    setChildMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      state.childMessages = action.payload;
    },
    setChildSessions: (state, action: PayloadAction<ChatSession[]>) => {
      state.childSessions = action.payload;
    },
    setSelectedSession: (state, action: PayloadAction<string>) => {
      state.selectedSession = action.payload;
    },
    setChatLoading: (state, action: PayloadAction<boolean>) => {
      state.chatLoading = action.payload;
    },
    setSelectedChild: (state, action: PayloadAction<string>) => {
      state.selectedChild = action.payload;
    },
    clearState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setChildren,
  addChild,
  updateChild,
  removeChild,
  setInitialized,
  setChildMessages,
  setChildSessions,
  setSelectedSession,
  clearState,
  setChatLoading,
  setSelectedChild,
} = childrenSlice.actions;

export default childrenSlice.reducer;