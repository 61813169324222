// ProfileScreen.tsx
import React, { useState } from 'react';
import { Card, Text, Button } from '@mantine/core';
import { TabSelector } from 'src/components/Toggles/TabSelectorToggle';
import { AccountView } from 'src/components/Layout/AccountView';
import DynamicLayout from 'src/components/Layout/DynamicLayout';
import { StyledContainer } from 'src/components/common/StyledComponents/Container';
import { StyledContainerInset } from 'src/components/common/StyledComponents/BackgroundContainer';
import { Notifications } from '@mantine/notifications';
import useResponsive from 'src/hooks/useResponsive';
import ParentChatSidebar from 'src/components/Sidebars/Parent/ParentSIdebarHome';
import ParentProfileSidebar from 'src/components/Sidebars/Parent/ParentSIdebarProfile';
import ManageSubscription from 'src/components/Layout/ManageSubscription';
import { useAppSelector } from 'src/redux/redux-store/hooks';
import useViewportHeight from 'src/hooks/useViewPortHeight';


const tabs = [
    { id: 'account', label: 'Account' },
    { id: 'subscription', label: 'Subscription' },
];

const ProfileScreen = () => {
    const { isMobile, backgroundColorExterior, backgroundColor } = useResponsive()
    const user = useAppSelector(state => state.user)
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    // useViewportHeight()

    const header = (<div className="flex flex-col">
        <Text size={"lg"} fw={"bold"}>Profile </Text>
    </div>)
    return (
        <DynamicLayout
            heading={header}
            SidebarComponent={ParentProfileSidebar}
        >
            <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
                <StyledContainerInset theme={{ backgroundColor: backgroundColor }}>
                    <Notifications
                        position={isMobile ? "top-center" : "bottom-right"}
                        limit={isMobile ? 1 : 5}
                    />
                    <div className='flex h-full w-full flex-col px-10 py-6'>
                        <TabSelector
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            className="w-72"
                        />
                        {activeTab === 'account' && <AccountView user={user} />}
                        {activeTab === 'subscription' && <ManageSubscription />}
                    </div>
                </StyledContainerInset>
            </StyledContainer>
        </DynamicLayout>
    );
};

export default ProfileScreen









