
  import {  useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
  import { useEffect, useMemo, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import useViewportHeight from "src/hooks/useViewPortHeight";
import { ChildrenData } from "src/interfaces/Children";
import ChildSelect from "src/components/common/Dropdown/SelectChild";
import { SidebarBottomSection } from "src/components/Sidebar/BottomSection";
import { ParentChildViewSection } from "src/components/Sidebar/BottomSection/ParentChildView";
import { ChatSession } from "src/interfaces/Chat";
import { BaseSidebar } from "./BaseSidebar";
import useChildrenData from "src/hooks/useChildrenData";
import { setSelectedChild, setSelectedSession } from "src/redux/redux-store/children/childrenReducer";
  
  interface SideBarProps {
    // opened: boolean;
    selectedSession: string
    childSessions: ChatSession[]
    onSelectSession: (id: string) => void
    children: ChildrenData[]
    selectedChild: string
    onSelectChild: (username: string) => void
  }
  
  
 const ParentChildViewSidebar = () => {
    const dispatch = useAppDispatch()
    const {children, selectedChild, childSessions, selectedSession,} = useAppSelector((state) => state.child)

    const onSelectSession = (id: string) => {
      dispatch(setSelectedSession(id));
    };


    const onSelectChild = (username: string) => {
       dispatch(setSelectedChild(username))
    }
    // useViewportHeight()

    const sidebarContent = useMemo(() => (
      <>
        <ChildSelect onSelectChild={onSelectChild} children={children} value={selectedChild} />
        <ParentChildViewSection selectSession={onSelectSession} selectedSession={selectedSession} chats={childSessions} /> 
      </>
    ), [children, selectedChild, selectedSession, childSessions])

    return (
      <BaseSidebar screenName="Learner View" >
        {sidebarContent}
      </BaseSidebar>
      )
  };
  
  export default ParentChildViewSidebar;
  